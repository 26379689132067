@import 'variables'

#body-content
  padding-left: $sidebar-width

#sidebar
  width: $sidebar-width
  position: fixed
  height: 100vh
  background: $color-main
  left: 0
  bottom: auto
  padding: 30px
  &.small
    width: 80px
  header
    margin: 30px 0 50px
  .logo
    width: 100%
  ul
    margin: 0
    padding: 0
    list-style: none
    li
      width: 100%
      padding: 12px 0 12px 0
      font-size: $font-size-p
      margin: 0
      a
        display: block
        color: white
      svg, i
        width: 30px
        display: inline-block
        vertical-align: top
        margin-top: 4px
        margin-right: 5px
      p
        width: calc(100% - 35px)
        display: inline-block
        font-size: $font-size-p
        margin: 0
      &.active
        a
          color: $color-second

@media screen and (max-width: 1200px)
  #body-content
    padding-left: $sidebar-width-small
  #sidebar
    width: $sidebar-width-small
    ul
      li
        font-size: mobile-font($font-size-p)
        p
          font-size: mobile-font($font-size-p)

.content
  width: auto
  padding: 20px 20px
@media screen and (min-width: 1200px)
  .content
    padding: 20px 5%

@media screen and (max-width: 993px)
  #body-content
    padding-left: 0
  #imprint
    display: none
  #sidebar
    z-index: 500
    padding: 0
    position: fixed
    top: 0
    left: 0
    right: 0
    width: 100%
    bottom: auto
    height: auto
    header
      margin: 0
      padding: 20px
      .logo
        width: 100%
        height: auto
      .nav-toggle-button
        i
          color: white
          font-size: 30px
          margin-top: -5px

    nav
      height: 0
      transition: 300ms all ease
      overflow: hidden
      &.open
        height: 100vh
        overflow: visible
      ul
        border-top: 2px solid white
        padding-top: 20%
        padding-left: 25%
        padding-right: 25%
        li, p
          font-size: 24px
        p
          width: calc(100% - 60px)
        i
          margin-right: 15px



  #environment-notice + main #sidebar
    top: 35px
  .content
    padding-top: 100px

@media screen and (max-width: 767px)
  hr
    margin: 10px 0
  #sidebar
    header
      .logo
        width: 75%
        margin-bottom: 3px
    nav
      ul
        padding: 20px
        li
          padding: 6px 0
        li, p
          font-size: 18px
        p
          width: calc(100% - 60px)
        i
          margin-right: 10px

#topTitle
  header
    font-size: 20px
    margin: 10px 0
    color: darken($color-gray, 50%)
    font-weight: $font-weight-slim

#imprint
  position: absolute
  bottom: 20px
  p
    color: white
    font-size: 13px
