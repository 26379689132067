@import 'variables'

.document-box, .product-change-box
  margin-bottom: 20px

  &.box
    padding: 0

  h3
    margin: 0
    font-size: 20px

  .box-header
    padding: 15px
    -webkit-transition: 300ms all ease
    moz-transition: 300ms all ease
    ms-transition: 300ms all ease
    o-transition: 300ms all ease
    transition: 300ms all ease

    &[data-toggle="collapse"]
      cursor: pointer

    &:hover, &[aria-expanded="true"]
      background-color: $color-second

      i, svg
        color: white

      .box-title
        > *
          color: white

  .box-body
    padding: 15px

  .box-title
    width: auto

  .box-actions
    float: right

    i, svg
      margin: 0 5px
      font-size: 18px
      color: $color-second
