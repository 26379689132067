@import 'variables'

.customer_products, .add-products
  .product-row
    margin-bottom: 10px
    border: 1px solid $color-main

    .product-header
      padding: 15px

    .product-name
      margin: 0

    .collapse, .collapsing
      border-top: 1px solid $color-main

    .product-body
      padding: 15px

    .product-expand i, svg
      font-size: 20px
      color: $color-main

.add-products
  i, svg
    float: right
    font-size: 18px
