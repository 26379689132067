$color-main: #1B3359
$color-second: #4991FF
$color-info: #E4F1FE
$color-third: #222540
$color-gray: #EAEAEA
$sidebar-width: 280px
$sidebar-width-small: 230px
$font-family: 'Lato', sans-serif
$font-weight-slim: 300
$font-weight-normal: 400
$font-size-p: 18px
$mobile-font-factor: 0.8

@function mobile-font($font-size)
  @return round($font-size * $mobile-font-factor)

