@import 'variables'

body
  font-family: $font-family

h1, h2, h3, h4, h5, h6
  color: $color-main
  font-weight: $font-weight-slim
  margin-top: 0

  small
    font-weight: $font-weight-slim
    color: darken($color-gray, 50%)

h1
  font-size: 36px
  margin-bottom: 35px

h1:empty
  display: none

h2
  font-size: 30px
  margin-bottom: 25px

.tablet-title, .mobile-title
  font-size: 20px
  color: white
  margin: 0

.mobile-title
  font-size: 12px
  line-height: 1.1

p, li
  font-size: 16px
  font-weight: $font-weight-slim

ul.no-list
  margin: 0
  padding: 0
  list-style: none

.form-control
  border-radius: 0
  box-shadow: none

.alert
  border-radius: 0

.alert-info
  background-color: $color-info
  border: 0

label
  color: $color-main
  font-weight: $font-weight-normal

.form-horizontal .form-group:last-child
  margin-bottom: 0

.form-horizontal
  label
    margin-top: 8px

.btn
  outline: none
  border-radius: 0
  padding: 8px 12px
  transition: 300ms all ease
  text-align: left

.btn-primary
  color: white
  background-color: $color-second
  border-color: $color-second

  &:hover
    color: $color-second
    background-color: white

@media screen and (max-width: 767px)
  .btn
    margin-bottom: 5px

.content
  section
    margin-bottom: 50px

[aria-expanded="true"] .fa-chevron-down
  -webkit-transform: rotate(180deg)
  -moz-transform: rotate(180deg)
  -ms-transform: rotate(180deg)
  -o-transform: rotate(180deg)
  transform: rotate(180deg)

.fa-chevron-down
  transition: 500ms transform ease

.modal
  .modal-content
    border: 0
    border-radius: 0

  .modal-header
    border: 0

.box
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, .04)
  margin-bottom: 20px
  position: relative

  &.no-header
    padding: 20px

  h1, h2, h3, h4, h5, h6
    &:only-child
      margin-bottom: 0

  .box-sticky-actions
    position: absolute
    top: 2px
    right: 6px

    i, svg
      margin-left: 4px

.box-header, .box-body
  padding: 20px

.btn
  > i, svg
    margin-right: 6px

a:focus, a:hover
  text-decoration: none

.file-list
  list-style: none
  margin: 0
  padding: 0

  li
    margin-bottom: 10px
    font-size: $font-size-p
    font-weight: $font-weight-slim

    p
      display: inline
      margin-top: -20px

    i, svg
      font-size: 24px
      margin-right: 10px
      transform: translateY(3px)

.document
  padding: 15px !important

  p
    font-size: 18px
    margin-bottom: 0

  i, svg
    font-size: 24px
    float: right
    margin-right: 10px

.bg-image
  width: 100%
  padding-bottom: 66%
  background: lightgray no-repeat center center
  background-size: cover

img
  max-width: 100%

.vich-image
  img
    margin-top: 10px
    margin-bottom: 5px

  p
    font-size: 13px
    font-weight: 400

.remove
  background-color: red
  height: 20px
  width: 20px
  border-radius: 100%
  text-align: center
  line-height: 20px
  cursor: pointer

  i, svg
    color: white

#product-documents
  .remove
    margin-top: 10px

#environment-notice
  position: fixed
  top: 0
  right: 0
  left: 0
  z-index: 500
  padding: 5px 20px
  text-align: center
  color: white

  p
    font-size: 18px
    margin: 0

  &.beta
    background: #F22613

  &.dev
    background: #26A65B


#environment-notice + main
  padding-top: 35px

#search-field
  width: 300px
  display: inline-block

@media screen and (max-width: 767px)
  #search-field
    width: 100%
    margin-bottom: 20px

.flashes
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 100
  .alert
    position: relative

  .alert-icon
    position: absolute
    padding: 15px 12px
    left: 0
    top: 0

  .flash-text-wrapper
    padding-left: 2em
    display: block
  .alert-dismissable .close, .alert-dismissible .close
    position: absolute
    top: 12px
    right: 15px

#environment-notice + .flashes
  top: 35px
