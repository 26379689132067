@import 'variables'

.autocomplete-suggestions
  border: 1px solid #FFF
  background: #FFF
  overflow: auto

  .autocomplete-suggestion
    padding: 2px 5px
    white-space: nowrap
    overflow: hidden
    strong
      font-weight: bold
      color: $color-main

  .autocomplete-selected
    background: lighten($color-second,30%)

  .autocomplete-group
    padding: 2px 5px
    strong
      display: block
      border-bottom: 1px solid #000
