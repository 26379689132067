@import 'variables'

.modal-content
  transition: 300ms all ease

.modal.success
  .modal-content
    background: #90C695

form
  .response
    margin-top: 15px
