@import 'variables'

.table
  >thead
    >tr
      >th
        background-color: $color-second
        border-bottom: 0
        color: white

.table-striped
  > tbody
    > tr:nth-of-type(odd)
      background-color: white
    > tr:nth-of-type(even)
      background-color: $color-gray

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th
  border: 0
