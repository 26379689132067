@import 'variables'

#login-content
  padding-top: 15vh
  padding-right: 200px

#login-widget
  max-width: 400px
  margin: 0 auto

.login-form-wrapper
  padding: 40px 25px
  box-shadow: 0 0 5px 5px rgba(0,0,0,.03)
  margin: 40px 0 30px
  input
    background: $color-gray
    border: 0
    &:focus
      border-color: #bbbbbb
      outline: 0
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0,0,0,0.6)
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 2px rgba(0,0,0,0.6)

@media screen and (max-width: 993px)
  #login-content
    padding-right: 0

@media screen and (max-width: 767px)
  #login-content
    padding-top: 0
