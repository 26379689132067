@import 'variables'

form .box
  padding: 0
  label
    font-size: 18px
    padding: 15px
    font-weight: $font-weight-normal
    margin: 0
    max-width: 60%
  .btn-file
    position: relative
    overflow: hidden
    input
      position: absolute
      top: 0
      right: 0
      margin: 0
      padding: 0
      font-size: 20px
      cursor: pointer
      opacity: 0
      filter: alpha(opacity=0)
  .file-upload-widget
    margin-right: 10px
    margin-left: 10px
    float: right
    .selected-file
      margin: 0 10px
      font-style: italic
      margin-top: 8px
      text-overflow: ellipsis
      max-width: 40%
      overflow: hidden
    .remove
      font-size: 22px
      margin: 0 10px
      color: white
    > *
      float: right


.box.product-change
  padding: 10px
  label
    display: none
  .form-group
    margin: 0
    .form-group
      display: inline-block
      width: 50%
      margin: 0
      vertical-align: top
      input[type="text"]
        border: 0
        border-bottom: 1px solid gray

